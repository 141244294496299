import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

import {logOut, tokenGetter} from '../utils/helpers';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private readonly router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        accept: '*/*',
        Authorization: 'Token ' + `${tokenGetter()}`
      }
    });
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 401:
            logOut();
            this.router.navigateByUrl('/sign-in');
            return EMPTY;
          default:
            return throwError(err);
        }
      } else {
        return throwError(err);
      }
    }));
  }
}
