import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material';
import {AddProjectDialogComponent} from '../../../shared/shared-modals/components/add-project-dialog/add-project-dialog.component';
import {LayoutService} from '../../services/layout.service';
import {ProjectsService} from '../../../shared/services/projects.service';
import {NgScrollbar} from 'ngx-scrollbar';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideComponent implements OnDestroy, AfterViewInit {
  @ViewChild('asideScroll') scroll: NgScrollbar;
  @Input() public projectList: Array<{ id: number, project_name: string }> = [];
  @Input() public showLoader;
  @Input() public totalProjectsCount = 0;

  private $updateScrollSubscription: Subscription;

  constructor(
    private readonly dialog: MatDialog,
    private readonly layoutService: LayoutService,
    private readonly router: Router,
    private readonly projectsService: ProjectsService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.$updateScrollSubscription = this.scroll.scrollable.elementScrolled()
      .subscribe((event: any) => {
        if (event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
          console.log('scroll end');
          this.projectsService.$updateProjectsAndSingleView.next({update: true, addNew: false});
          this.scroll.update();
        }
      });
  }

  public updateProjectView(): void {
    this.router.navigateByUrl('/dashboard');
    this.projectsService.$updateProjectsAndSingleView.next({update: true, addNew: false});
  }

  public openAddProjectDialog(): void {
    this.dialog.open(AddProjectDialogComponent);
  }

  public closeAside(): void {
    this.layoutService.closeAsideShowMenuIcon();
  }

  ngOnDestroy(): void {
    if (this.$updateScrollSubscription) {
      this.$updateScrollSubscription.unsubscribe();
    }
  }
}
