import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ValidationMessages} from '../../../utils/validation-messages';
import {FormValidation} from '../../../utils/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-field, [app-field]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldComponent extends FormValidation implements OnInit, OnChanges {
  @Input() public validationMessage: {[key: string]: Array<{[key: string]: string}>} = ValidationMessages;
  @Input() public $errorsFromParentComponent: Subject<void>;
  @Input() public fieldInfo: Partial<{
    fieldType: string,
    inputType: string,
    placeholder: string,
    formG: FormGroup,
    formCName: any,
    submit: boolean,
    errorMsg: string,
    validation: string,
    classes: string,
    typeClasses: string,
    iconName: string,
    checkText: string,
    radioText: string,
    icon: '',
    checked: boolean,
    readonly?: boolean
    value?: string // only for 'readonly===true' case
  }>;
  @Output() public onEnter: EventEmitter<string> = new EventEmitter();
  public fieldType: string;
  public placeholder = '';
  public formG: FormGroup;
  public formCName;
  public classes = '';
  public typeClasses = '';
  public iconName = '';
  public validation: string;
  public checkText = '';
  public radioText = '';
  public icon = '';
  public checked: boolean;
  errorMsg = '';
  private submit: boolean;

  constructor(private readonly chd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.$errorsFromParentComponent) {
      this.$errorsFromParentComponent.subscribe((event: any) => {
        this.chd.markForCheck();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.fieldInfo) {
      this.submit = this.fieldInfo.submit;
      this.fieldType = this.fieldInfo.fieldType || 'text';
      this.placeholder = this.fieldInfo.placeholder;
      this.formG = this.fieldInfo.formG;
      this.formCName = this.fieldInfo.formCName;
      this.errorMsg = this.fieldInfo.errorMsg;
      this.validation = this.fieldInfo.validation;
      this.classes = this.fieldInfo.classes;
      this.classes = this.fieldInfo.typeClasses;
      this.iconName = this.fieldInfo.iconName;
      this.checkText = this.fieldInfo.checkText;
      this.radioText = this.fieldInfo.radioText;
      this.icon = this.fieldInfo.icon;
      this.checked = this.fieldInfo.checked;
    }
  }

  onEnterEvent($event) {
    this.onEnter.emit($event);
  }
}
