import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/components/header/header.component';
import {FooterComponent} from './layout/components/footer/footer.component';
import {NotfoundComponent} from './shared/components/notfound/notfound.component';
import {AsideComponent} from './layout/components/aside/aside.component';
import {HeaderDashboardComponent} from './layout/components/header-dashboard/header-dashboard.component';
import {LayoutComponent} from './layout/components/layout/layout.component';
import {MatDialogModule, MatTooltipModule} from '@angular/material';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {CustomComponentsModule} from './shared/custom-components/custom-components.module';
import {SharedModalsModule} from './shared/shared-modals/shared-modals.module';
import {ClickOutsideModule} from 'ng-click-outside';
import {ToastrModule} from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotfoundComponent,
    AsideComponent,
    HeaderDashboardComponent,
    LayoutComponent,
  ],
  imports: [
    CoreModule.forRoot(),
    AppRoutingModule,
    CustomComponentsModule,
    MatTooltipModule,
    MatDialogModule,
    ClickOutsideModule,
    SharedModalsModule,
    ToastrModule.forRoot()
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
