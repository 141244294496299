import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

// services
import {CardsDataService} from '../../../services/cards-data.service';

// utils
import {FormValidation} from '../../../utils/forms';

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './create-name-dialog.component.html',
  styleUrls: ['./create-name-dialog.component.scss']
})
export class CreateNameDialogComponent extends FormValidation implements OnInit {
  public form: FormGroup;
  public isSubmitted = false;
  private projectID;

  constructor(
    private formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<CreateNameDialogComponent>,
    private readonly cardsDataService: CardsDataService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit() {
    this.projectID = this.data.projectID;
    this.form = this.formBuilder.group({
      name: new FormControl(null, [Validators.required, Validators.pattern(/[a-zA-Z0-9\s]+/)])
    });
  }

  public onProjectNameSubmit(): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.cardsDataService.newlyCreatedCard = {
        project: this.projectID,
        name: this.form.value.name
      };

      this.dialogRef.close(true);
      this.router.navigate(['/generate-steps', this.projectID]);
    }
  }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

}
