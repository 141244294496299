import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/components/layout/layout.component';
import {CanActiveGuard} from './shared/guards/can-active.guard';
import {NotfoundComponent} from './shared/components/notfound/notfound.component';
import {CanActivateAuthGuard} from './shared/guards/can-activate-auth.guard';

const routes: Routes = [
  {
    path: '', component: LayoutComponent, canActivate: [CanActiveGuard], children: [
      {
        path: 'profile',
        data: {
          hamburgerClass: '',
          pageContainerClass: '',
          asideClass: 'open'
        },
        loadChildren: './modules/profile/profile.module#ProfileModule'
      },
      {
        path: 'dashboard',
        data: {
          hamburgerClass: 'color-secondary',
          pageContainerClass: '',
          asideClass: 'open'
        },
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'contest',
        data: {
          hamburgerClass: 'color-secondary',
          pageContainerClass: '',
          asideClass: 'open'
        },
        loadChildren: './modules/contest/contest.module#ContestModule'
      },
      {
        path: 'generate-steps/:id',
        data: {
          hamburgerClass: 'color-white-50 show',
          pageContainerClass: 'generate-steps',
          asideClass: 'close'
        },
        loadChildren: './modules/generate-name-steps/generate-name-steps.module#GenerateNameStepsModule'
      },
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
    ]
  },
  {
    path: 'contest/vote',
    loadChildren: './modules/contest-vote/contest-vote.module#ContestVoteModule'
  },
  {
    path: '',
    canActivate: [CanActivateAuthGuard],
    loadChildren: './modules/authorization/authorization.module#AuthorizationModule'
  },
  {
    path: '**', component: NotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
