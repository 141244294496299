import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormValidation} from '../../../utils/forms';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {ProjectsService} from '../../../services/projects.service';

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss']
})
export class AddProjectDialogComponent extends FormValidation implements OnInit {
  public projectNameForm: FormGroup;
  public projectNameSubmit = false;
  public message = '';

  constructor(
    private formBuilder: FormBuilder,
    protected dialogRef: MatDialogRef<AddProjectDialogComponent>,
    private readonly projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit() {
    this.message = this.data && this.data.isFirstProject ? 'FIRST' : '';
    this.projectNameForm = this.formBuilder.group({
      project_name: new FormControl(null, [Validators.required, Validators.pattern(/[a-zA-Z0-9\s]+/)])
    });
  }

  public onProjectNameSubmit(): void {
    this.projectNameSubmit = true;
    if (this.projectNameForm.valid) {
      const formData = this.projectNameForm.value;
      this.projectsService.createProject({
        project_name: formData.project_name
      }).subscribe(
        res => {
          this.dialogRef.close(true);
          // this.router.navigate(['/dashboard/project', res.id]);
          this.projectsService.$updateProjectsAndSingleView.next({update: true, addNew: true});
        },
        (err: HttpErrorResponse) => {
          // TODO show error message
        }
      );
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

}
