import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent implements OnInit {
  type = 'button';
  classes = '';
  icon = '';
  @Input() buttonInfo: {type: string, classes: string, icon: string};

  constructor() { }

  ngOnInit() {
      this.type = this.buttonInfo.type;
      this.classes = this.buttonInfo.classes;
      this.icon = this.buttonInfo.icon;
    }
}
