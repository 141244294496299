import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {AuthorizationService} from './modules/authorization/services/authorization.service';
import {isAuth, userGetter} from './shared/utils/helpers';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'ngClient';

  constructor(private auth: AuthorizationService,
              private readonly renderer: Renderer2,
              @Inject(MatDialog) private dialog: MatDialog) {
    if (isAuth()) {
      this.auth.userSubject.next(userGetter());
    }
  }

  ngOnInit(): void {
    this.dialog.afterAllClosed.subscribe(() => {
      this.renderer.removeClass(document.body, 'opened-dialog');
    });
    this.dialog.afterOpened.subscribe(() => {
      this.renderer.addClass(document.body, 'opened-dialog');
    });
  }
}
