import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {AuthorizationService} from '../../../modules/authorization/services/authorization.service';
import {UserModel} from '../../../shared/models/user.model';
import {userGetter} from '../../../shared/utils/helpers';


@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss']
})
export class HeaderDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardHeader') private dashboardHeader: ElementRef;
  public isSubContentVisible = false;
  public userData: { first_name: string; last_name: string; circleText: string } = {
    first_name: '',
    last_name: '',
    circleText: '',
  };
  public isNotificationSubContentVisible = false;
  private logOutSubscription: Subject<void> = new Subject();

  constructor(private readonly authService: AuthorizationService) {
  }

  ngOnInit(): void {
    const data: UserModel = userGetter();

    let circleText = '';
    if (data.first_name) {
      this.userData.first_name = data.first_name;
      circleText = data.first_name.substr(0, 1).toUpperCase();

      if (data.last_name) {
        this.userData.last_name = data.last_name;
        circleText += ` ${data.last_name.substr(0, 1).toUpperCase()}`;
      }
    } else if (data.username) {
      this.userData.first_name = data.username;
      circleText = data.username.substr(0, 1).toUpperCase();
    } else {
      circleText = 'A';
      this.userData.first_name = data.first_name;
      this.userData.last_name = data.last_name;
    }

    this.userData.circleText = circleText;
  }

  public logOut(): void {
    this.authService.logout().pipe(takeUntil(this.logOutSubscription)).subscribe(
      () => {
      },
    );
  }

  public toggleSubContent(): void {
    this.isSubContentVisible = !this.isSubContentVisible;
  }

  public toggleNotificationSubContent(): void {
    this.isNotificationSubContentVisible = !this.isNotificationSubContentVisible;
  }

  public closeSubContent(): void {
    this.isSubContentVisible = false;
  }

  public closeNotificationSubContent(): void {
    this.isNotificationSubContentVisible = false;
  }

  ngOnDestroy(): void {
    this.logOutSubscription.next();
    this.logOutSubscription.complete();
  }
}
