import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public $toggleAsideState: EventEmitter<boolean>;
  public $toggleMenuIconState: EventEmitter<boolean>;

  constructor() { }

  public closeAside(): void {
    this.$toggleAsideState.emit(false);
  }

  public openAside(): void {
    this.$toggleAsideState.emit(true);
  }

  public showMenuIcon(): void {
    this.$toggleMenuIconState.emit(true);
  }

  public hideMenuIcon(): void {
    this.$toggleMenuIconState.emit(false);
  }

  public closeAsideShowMenuIcon(): void {
    this.closeAside();
    this.showMenuIcon();
  }
}
