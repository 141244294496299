import {UserModel} from '../models/user.model';
import {HttpParams} from '@angular/common/http';
import {IParam} from '../models/params.model';

export function tokenSetter(token: string): void {
  localStorage.setItem('access_token', token);
}

export function userSetter(user: string | UserModel): void {
  localStorage.setItem('user', JSON.stringify(user || null));
}

export function userGetter(): UserModel {
  return JSON.parse(localStorage.getItem('user'));
}

export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

export function logOut(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
}

export function isAuth(): boolean {
  return !!localStorage.getItem('access_token');
}

export function hasClass(element, className): boolean {
  return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function anyToHttpParams(obj: IParam): HttpParams {
  let param = new HttpParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      param = param.set(key, obj[key] as string);
    }
  }
  return param;
}
