export const AuthValidationMessages = {
  EMAIL_REQUIRED: 'Email can not be empty.',
  EMAIL_VALID: 'Please enter a valid email address.',
  FIRST_NAME_REQUIRED: 'First name can not be empty.',
  USERNAME: 'Username can not be empty.',
  LAST_NAME_REQUIRED: 'Last name can not be empty.',
  PASSWORD_REQUIRED: 'Password can not be empty..',
  CONFIRM_PASSWORD: 'Confirm Password can not be empty.',
  DO_NOT_MATCH: 'Passwords do not match..',
  FORGOT: 'Account does not exist.',
  TITLE_REQUIRED: 'Title can not be empty.',
  DESCRIPTION_MAX: 'Description should not exceed 500 symbols.',
  FILE_REQUIRED: 'Must provide a listing image.',
  CATEGORY_REQUIRED: 'Select category.',
  VALID_IMAGE: 'Uploaded file is not a valid image.',
  IMAGE_REQUIRED: 'Must provide a listing image.',
  DAILY_RENTAL: 'Daily rental can not be empty.',
  INVALID_OLD_PASSWORD: 'Old Password is invalid.',
  ONLY_LETTERS: 'Only letters are allowed.'
};

export const ValidationMessages = {
  email: [
    {type: 'required', message: 'Please enter your email address.'},
    {type: 'email', message: 'Please enter a valid email address.'},
    {type: 'not_exist', message: 'Email address does not exist.'},
    {type: 'email_already_exist', message: 'User with this email address already exist.'},
    {type: 'pattern', message: 'Please enter a valid email address.'},
    {type: 'inactive', message: 'Your account is inactive.'}
  ],
  first_name: [
    {type: 'required', message: 'First name can not be empty.'},
    {type: 'pattern', message: 'First name must contain only numbers or letters.'}
  ],
  last_name: [
    {type: 'required', message: 'Last name can not be empty.'},
    {type: 'pattern', message: 'Last name must contain only letters.'}
  ],
  name: [
    {type: 'required', message: 'Please enter your Project Name.'},
    {type: 'minlength', message: 'Name must be at least 2 characters long.'},
    {type: 'pattern', message: 'Name must contain only letters.'}
  ],
  username: [
    {type: 'required', message: 'Username can not be empty.'},
    {type: 'minlength', message: 'Username must be at least 3 characters long.'},
    {type: 'username_already_exist', message: 'This username already exists.'}
  ],
  password: [
    {type: 'required', message: 'Please enter your password.'},
    {type: 'pattern', message: 'Password must be at least 8 characters long ' +
        'and contain letters, numbers and specific symbols.'},
    {type: 'invalid', message: 'Current password is invalid.'},
    {type: 'minlength', message: 'Password must be at least 8 characters long ' +
        'and contain letters, numbers and specific symbols.'},
    {type: 'required-for-new-password', message: 'Current password is required.'}
  ],
  confirm_password: [
    {type: 'required', message: 'Confirm Password can not be empty.'},
    {type: 'mustMatch', message: 'Passwords do not match.'}
  ],
  new_password: [
    {type: 'required', message: 'Please enter new password.'},
    {type: 'pattern', message: 'Password must be at least 8 characters long ' +
        'and contain letters, numbers and specific symbols.'},
    {type: 'minlength', message: 'Password must be at least 8 characters long ' +
        'and contain letters, numbers and specific symbols.'},
  ],
  confirm_new_password: [
    {type: 'required', message: 'Confirm Password can not be empty.'},
    {type: 'mustMatch', message: 'New passwords do not match.'}
  ],
  privacy_police: [
    {type: 'required', message: 'You must accept our Terms & Conditions.'}
  ],
  affixes: [
    {type: 'required', message: 'name is required.'},
    {type: 'pattern', message: AuthValidationMessages.ONLY_LETTERS},
    {type: 'minlength', message: 'min length 3'},
  ],
  specific_world1: [
    {type: 'required', message: 'name is required'},
    {type: 'pattern', message: AuthValidationMessages.ONLY_LETTERS}
  ],
  specific_world2: [
    {type: 'pattern', message: AuthValidationMessages.ONLY_LETTERS}
  ],
  project_name: [
    {type: 'required', message: 'Project name' +
      ' can not be empty'},
  ],
  brand_name: [
    {type: 'required', message: 'Brant name is required'},
    {type: 'pattern', message: AuthValidationMessages.ONLY_LETTERS}
  ],
  synony1: [
    {type: 'required', message: 'synonym name is required'},
    {type: 'pattern', message: AuthValidationMessages.ONLY_LETTERS}
  ],
  only_digits: [
    {type: 'onlyDigits', message: 'Only positive digits are allowed.'},
    {type: 'required', message: 'This field is required.'}
  ]
};
