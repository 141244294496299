import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';

import {UserModel} from '../../../shared/models/user.model';
import {BaseRestService} from '../../../shared/providers/base-rest.service';
import {logOut, tokenSetter, userSetter} from '../../../shared/utils/helpers';
import {environment} from '../../../../environments/environment';
import {SuccessModel} from '../../../shared/models/success.model';
import {ResetPassword} from '../models/reset-password.model';
import {ChangePassword} from '../models/change-password.model';
import {ForgotPassword} from '../models/forgot-password.model';
import {ConfirmPassword} from '../models/confirm-password.model';
import {SignUp} from '../models/sign-up.model';
import {AuthToken} from '../models/auth-token.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends BaseRestService<UserModel> {
  public userSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  public user = this.userSubject.asObservable();
  private httpBackend: HttpClient;
  private URL = `${environment.apiUrl}auth-users`;

  constructor(private readonly httpClient: HttpClient,
              private readonly handler: HttpBackend,
              private readonly router: Router) {
    super(httpClient);
    this.httpBackend = new HttpClient(this.handler);
  }

  public signUp(data: SignUp): Observable<SuccessModel> {
    return this.httpBackend.post<SuccessModel>(`${this.URL}/sign-up/`, data);
  }

  public signIn(data: AuthToken): Observable<UserModel> {
    return this.httpBackend.post(`${this.URL}/login/`, data)
      .pipe(tap((res: UserModel): void => {
          tokenSetter(res.token);
          userSetter(res);
          this.userSubject.next(res);
          this.router.navigate(['/dashboard']);
        })
      );
  }

  public logout(): Observable<void> {
    return this.httpClient.get<void>(`${this.URL}/logout/`).pipe(tap(() => {
        this.userSubject.next(null);
        logOut();
        this.router.navigate(['/sign-in']);
      })
    );
  }

  public forgotPassword(data: ForgotPassword): Observable<SuccessModel> {
    return this.httpBackend.post<SuccessModel>(`${this.URL}/forgot-password/`, data);
  }

  public resetPassword(data: ResetPassword): Observable<SuccessModel> {
    return this.httpBackend.post<SuccessModel>(`${this.URL}/reset-password/${data.token}/`, data);
  }

  public changePassword(data: ChangePassword): Observable<SuccessModel> {
    return this.post<ChangePassword, SuccessModel>(`${this.URL}/change-password/`, data);
  }

  public confirmAccount(data: ConfirmPassword): Observable<SuccessModel> {
    return this.httpBackend.post<SuccessModel>(`${this.URL}/confirm-account/`, data);
  }

  public resendEmail(email: string): Observable<{ detail: string | string[] }> {
    return this.httpBackend.post<{ detail: string | string[] }>(`${this.URL}/resend-email/`, {email});
  }
}
