import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-button, [app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() public buttonInfo: {type: string, classes: string, btnText: string};
  public type = 'button';
  public classes = '';
  public btnText = '';
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.buttonInfo) {
      this.type = this.buttonInfo.type;
      this.classes = this.buttonInfo.classes;
      this.btnText = this.buttonInfo.btnText;
    }
  }

}
