import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {isAuth} from '../utils/helpers';


@Injectable({
  providedIn: 'root'
})
export class CanActiveGuard implements CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!isAuth()) {
      this.router.navigate(['/sign-in']);
      return false;
    }
    return true;
  }
}
