import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

import {AuthorizationService} from '../../../modules/authorization/services/authorization.service';
import {UserModel} from '../../../shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public user: UserModel;
  private subscription: Subscription;
  private logOutSubscription: Subject<void> = new Subject();

  constructor(
    private readonly auth: AuthorizationService
  ) {  }

  ngOnInit(): void {
    this.subscription = this.auth.user.subscribe((res: UserModel) => this.user = res);
  }

  public logout(): void {
    this.auth.logout()
      .pipe(takeUntil(this.logOutSubscription))
      .subscribe(
      (): void => {}
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.logOutSubscription.next();
    this.logOutSubscription.complete();
  }
}
