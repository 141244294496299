import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ButtonIconComponent} from './components/button-icon/button-icon.component';
import {AnimatedLogoComponent} from './components/animated-logo/animated-logo.component';
import {ButtonComponent} from './components/button/button.component';
import {FieldComponent} from './components/field/field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ButtonIconComponent,
    AnimatedLogoComponent,
    ButtonComponent,
    FieldComponent
  ],
  exports: [
    ButtonIconComponent,
    AnimatedLogoComponent,
    ButtonComponent,
    FieldComponent
  ]
})
export class CustomComponentsModule {
}

