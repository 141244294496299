import { Injectable } from '@angular/core';
import {ResponseModel} from '../models/response.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseRestService<T> {

  protected apiUrl: string;

  protected constructor(protected readonly http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  protected create<Type>(endpoint: string, item: Type): Observable<T> {
    return this.http.post<T>(`${this.apiUrl + endpoint}/`, item);
  }

  protected post<Type, Resp>(endpoint: string, item: Type): Observable<Resp> {
    return this.http.post<Resp>(`${this.apiUrl + endpoint}/`, item);
  }

  protected getByPagination(endpoint: string, params?: HttpParams): Observable<ResponseModel<T>> {
    return this.http.get<ResponseModel<T>>(`${this.apiUrl}${endpoint}/`, {
      params
    });
  }

  protected getById(endpoint: string, id: number, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}${endpoint}/${id}/`, {
      headers
    });
  }

  protected editPatch<Type>(endpoint: string, data: any, id?: number): Observable<Type> {
    return this.http.patch<Type>(`${this.apiUrl + endpoint}/${id && (id + '/')}`, data);
  }

  protected editPut<Type>(endpoint: string, id: number, data: T): Observable<Type> {
    return this.http.put<Type>(`${this.apiUrl + endpoint}/${id}/`, data);
  }

  protected deleteById(endpoint: string, id: number) {
    return this.http.delete(`${this.apiUrl + endpoint}/${id}/`);
  }
}
