import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {isAuth} from '../utils/helpers';

@Injectable({
    providedIn: 'root'
})
export class CanActivateAuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (isAuth()) {
            this.router.navigate(['/dashboard']);
            return false;
        }
        return true;
    }
}
