import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationStart, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

import {LayoutRouterDataModel} from '../../models/layout-router-data.model';
import {ResponseModel} from '../../../shared/models/response.model';
import {ProjectsService} from '../../../shared/services/projects.service';
import {LayoutService} from '../../services/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('aside', {read: ElementRef}) private aside: ElementRef;
  @ViewChild('iconMenu') private iconMenu: ElementRef;
  public isRequestSuccess = false;
  public routerData: LayoutRouterDataModel;
  public projectList: ResponseModel<any> = {count: 0, next: null, previous: null, results: []};
  private getProjectsSubscription: Subject<void> = new Subject();
  private asideSubscription: Subject<void> = new Subject();
  private menuIconSubscription: Subject<void> = new Subject();
  private page = 0;
  public showLoader;
  private projectsCount = 0;

  constructor(private readonly projectsService: ProjectsService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly renderer: Renderer2,
              private readonly layoutService: LayoutService,
              private readonly router: Router) {
    this.layoutService.$toggleAsideState = new EventEmitter();
    this.layoutService.$toggleMenuIconState = new EventEmitter();
  }

  ngOnInit() {
    this.subscribeToRouterEvents();
    this.getProjects({update: true, addNew: true});
    this.subscribeToAsideStateChange();
    this.subscribeToMenuIconStateChange();
    this.subscribeToUpdateProject();
  }

  private subscribeToRouterEvents(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        this.routerData = event.snapshot.data as any;
      } else if (event instanceof NavigationEnd) {
        // this.changeRouteByProjectsCount();
      }
    });
    this.activatedRoute.firstChild.data.subscribe((data: any) => {
      this.routerData = data;
    });
  }

  private subscribeToAsideStateChange(): void {
    this.layoutService.$toggleAsideState
      .pipe(takeUntil(this.asideSubscription))
      .subscribe(state => {
        if (state) {
          this.showASideMenu();
        } else {
          this.hideAsideMenu();
        }
      });
  }

  private subscribeToMenuIconStateChange(): void {
    this.layoutService.$toggleMenuIconState
      .pipe(takeUntil(this.menuIconSubscription))
      .subscribe(state => {
        if (state) {
          this.showMenuIcon();
        } else {
          this.hideMenuIcon();
        }
      });
  }

  private subscribeToUpdateProject(): void {
    this.projectsService.$updateProjectsAndSingleView.pipe(takeUntil(this.getProjectsSubscription))
      .subscribe((update: { update: boolean, addNew: boolean }) => {
        this.getProjects(update);
      });
  }

  private getProjects(updateProjectView?: { update: boolean, addNew: boolean }): void {
    this.showLoader = true;

    if (updateProjectView.addNew) {
      this.page = 1;
    } else if (this.projectsCount === this.projectList.results.length && this.projectsCount !== 0) {
      this.showLoader = false;
      this.isRequestSuccess = true;
      console.log('end of results');
      return;
    } else {
      this.page = this.page === 0 ? 1 : this.page + 1;
    }

    this.projectsService.getProjects(this.page).pipe(takeUntil(this.getProjectsSubscription)).subscribe(
      (res: ResponseModel<any>) => {
        this.isRequestSuccess = true;
        if (this.page === 1) {
          // first request (for 1st page)
          this.projectList = res;
        } else {
          // other requests (for [2, ...] page
          this.projectList = {
            next: res.next,
            previous: res.previous,
            count: res.count,
            results: [...this.projectList.results, ...res.results]
          };
        }

        this.projectsService.projectList = this.projectList;

        if (updateProjectView.addNew) {
          this.changeRouteByProjectsCount(updateProjectView);
        }

        this.projectsCount = this.projectList.count;
        this.showLoader = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.page--;
        }
        this.showLoader = false;
      }
    );
  }

  private changeRouteByProjectsCount(showFirstItem: { update: boolean, addNew: boolean }): void {
    if (this.projectList.count > 0) {
      if (this.router.url.includes('/dashboard') && showFirstItem.update) {
        this.router.navigate([`/dashboard/project`, this.projectList.results[0].id]);
      }
      this.renderer.removeClass(this.aside.nativeElement, 'no-projects');
    } else if (this.projectList.count === 0) {
      this.renderer.addClass(this.aside.nativeElement, 'no-projects');
      this.router.navigate(['/dashboard']);
    }
  }

  public openAside(): void {
    this.showASideMenu();
    this.hideMenuIcon();
  }

  private hideAsideMenu(): void {
    this.renderer.addClass(this.aside.nativeElement, 'hide');
    this.renderer.removeClass(this.aside.nativeElement, 'show');
  }

  private showASideMenu(): void {
    this.renderer.removeClass(this.aside.nativeElement, 'hide');
    this.renderer.addClass(this.aside.nativeElement, 'show');
  }

  private hideMenuIcon(): void {
    this.renderer.addClass(this.iconMenu.nativeElement, 'hide');
  }

  private showMenuIcon(): void {
    this.renderer.removeClass(this.iconMenu.nativeElement, 'hide');
  }

  ngOnDestroy(): void {
    this.getProjectsSubscription.next();
    this.getProjectsSubscription.complete();
    this.asideSubscription.next();
    this.asideSubscription.complete();
    this.menuIconSubscription.next();
    this.menuIconSubscription.complete();
  }

}
