import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {BaseRestService} from '../providers/base-rest.service';
import {ProjectDataModel} from '../../modules/dashboard/models/project-data.model';
import {ResponseModel} from '../models/response.model';
import {anyToHttpParams} from '../utils/helpers';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseRestService<ProjectDataModel> {
  private projectUrl = 'create-project';
  public $updateProjectsAndSingleView: Subject<{update: boolean, addNew: boolean}> = new Subject();
  public projectList: ResponseModel<any> = {count: 0, results: []};

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  createProject(data): Observable<ProjectDataModel> {
    return this.post(`${this.projectUrl}`, data);
  }

  getProjects(page: number): Observable<ResponseModel<ProjectDataModel>> {
    return this.getByPagination(`${this.projectUrl}`, anyToHttpParams({page}));
  }

  getProjectById(id): Observable<ProjectDataModel> {
    return this.getById(`${this.projectUrl}`, id);
  }
}
