import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddProjectDialogComponent} from './components/add-project-dialog/add-project-dialog.component';
import {CreateNameDialogComponent} from './components/create-name-dialog/create-name-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomComponentsModule} from '../custom-components/custom-components.module';
import { ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    AddProjectDialogComponent,
    CreateNameDialogComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CustomComponentsModule
  ],
  exports: [
    AddProjectDialogComponent,
    CreateNameDialogComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [
    AddProjectDialogComponent,
    CreateNameDialogComponent,
    ConfirmationDialogComponent
  ]
})
export class SharedModalsModule { }
