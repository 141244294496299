import {Injectable} from '@angular/core';
import {CardPayloadModel, CardResponseModel} from '../../modules/dashboard/models/card-payload.model';

@Injectable({
  providedIn: 'root'
})
export class CardsDataService {

  public newlyCreatedCard: CardPayloadModel;
  public responseCardModel: CardResponseModel;

  constructor() {
  }

  public reset(): void {
    this.newlyCreatedCard = null;
  }
}
